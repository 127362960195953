import React from 'react';
import { PropTypes, observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const Form = observer(({ form, oceanCarriers }) => {
  const classes = useStyles();
  const { values, loading, submit, setValueByElement, setValue, submitting, errors } = form;

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    submit(e);
  }

  return <>
    <Box visibility={loading ? 'visible' : 'hidden'}><LinearProgress /></Box>
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth error={errors.all_scac_codes.length > 0}>
        <FormControlLabel
          control={<Switch checked={values.all_scac_codes} onChange={setValueByElement} name="all_scac_codes" />}
          label="ALL SCAC CODE"
        />
        <FormHelperText>{errors.all_scac_codes.join(' ')}</FormHelperText>
      </FormControl>
      <FormControl fullWidth error={errors.scac_codes.length > 0}>
        <InputLabel>SCAC CODES</InputLabel>
        <Select
          multiple
          value={values.scac_codes.toJS()}
          name="scac_codes"
          onChange={e => setValue(e.target.name, e.target.value)}
          input={<Input />}
          renderValue={(selected) => selected.join(', ')}
          required={!values.all_scac_codes}
          disabled={values.all_scac_codes}
        >
          {oceanCarriers.map((o) => (
            <MenuItem key={o.id} value={o.scac_code}>
              <Checkbox checked={values.scac_codes.indexOf(o.scac_code) > -1} />
              <ListItemText primary={o.scac_code} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{errors.scac_codes.join(' ')}</FormHelperText>
      </FormControl>


      <Box mt={2} display="flex" flexDirection="row-reverse" className={classes.buttonGroup}>
        <Button
          variant="contained"
          disabled={submitting}
          onClick={history.goBack}
        >
          Cancel
      </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={submitting}
        >
          Save
      </Button>
      </Box>
    </form>
  </>
})

export default Form;

Form.propTypes = {
  form: PropTypes.objectOrObservableObject.isRequired,
}
