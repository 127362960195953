import React from 'react';
import { PropTypes, observer } from 'mobx-react';
import ReactPropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Switch from '@material-ui/core/Switch';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const Contacts = observer(({ contacts, onAdd, onRemove, size = "small" }) => {
  const classes = useStyles();

  return <>

    <Table className={classes.table} size={size}>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Emailable</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Phone</TableCell>
          <TableCell>Cell</TableCell>
          <TableCell>Fax</TableCell>
          <TableCell>Remark</TableCell>
          <TableCell><IconButton color="primary" onClick={onAdd}><AddCircleIcon /></IconButton></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {contacts.map((contact, index) => (
          <Contact size={size} key={index} contact={contact} onRemove={onRemove} />
        ))}
      </TableBody>
    </Table>

  </>
})

const Contact = observer(({ contact, size, onRemove }) => {
  return <TableRow>
    <TableCell>
      <TextField
        required
        fullWidth
        margin="dense"
        name="name"
        value={contact.name || ''}
        variant="outlined"
        onChange={contact.setValueByElement}
        size={size}
      />
    </TableCell>
    <TableCell>
      <TextField
        fullWidth
        margin="dense"
        name="type"
        value={contact.type || ''}
        variant="outlined"
        onChange={contact.setValueByElement}
        size={size}
      />
    </TableCell>
    <TableCell>
      <Switch
        color="primary"
        name="emailable"
        checked={contact.emailable}
        variant="outlined"
        onChange={contact.setValueByElement}
        size={size}
      />
    </TableCell>
    <TableCell>
      <TextField
        fullWidth
        margin="dense"
        type="email"
        name="email"
        value={contact.email || ''}
        variant="outlined"
        onChange={contact.setValueByElement}
        size={size}
      />
    </TableCell>
    <TableCell>
      <TextField
        fullWidth
        margin="dense"
        name="phone"
        value={contact.phone || ''}
        variant="outlined"
        onChange={contact.setValueByElement}
        size={size}
      />
    </TableCell>
    <TableCell>
      <TextField
        fullWidth
        margin="dense"
        name="cell"
        value={contact.cell || ''}
        variant="outlined"
        onChange={contact.setValueByElement}
        size={size}
      />
    </TableCell>
    <TableCell>
      <TextField
        fullWidth
        margin="dense"
        name="fax"
        value={contact.fax || ''}
        variant="outlined"
        onChange={contact.setValueByElement}
        size={size}
      />
    </TableCell>
    <TableCell>
      <TextField
        fullWidth
        margin="dense"
        name="remark"
        value={contact.remark || ''}
        variant="outlined"
        onChange={contact.setValueByElement}
        size={size}
      />
    </TableCell>
    <TableCell>
      <IconButton onClick={() => onRemove(contact)}><RemoveCircleOutlineIcon /></IconButton>
    </TableCell>
  </TableRow>
})

export default Contacts;


Contacts.propTypes = {
  contacts: PropTypes.arrayOrObservableArray.isRequired,
  // errors: PropTypes.objectOrObservableObject.isRequired,
  onAdd: ReactPropTypes.func.isRequired,
  onRemove: ReactPropTypes.func.isRequired,
}
