import React from 'react';
import { useHistory } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import Form from './index';

export default () => {
  const history = useHistory();

  return <>
    <Dialog maxWidth="xl" fullWidth open={true} onClose={history.goBack}>
      <DialogContent>
        <Form />
      </DialogContent>
    </Dialog>
  </>
}
