import { types, flow } from 'mobx-state-tree';
import { resolveFormStore } from 'mst-helper';
import { merge, get, mapValues } from 'lodash';

export const resolveModelFormStore = (params = {}) => {
  const defaultParams = {
    modelAttributes: {},
    collection: null,
    entities: null,
    service: null,
    loadServiceMethod: 'show',
    updateServiceMethod: 'update',
    storeServiceMethod: 'store',
    actions: () => { },
  }

  const {
    modelAttributes,
    collection,
    entities,
    service,
    loadServiceMethod,
    updateServiceMethod,
    storeServiceMethod,
    actions,
  } = merge(defaultParams, params);

  const formValues = {
    valuesAttributes: {
      id: types.maybeNull(types.number),
      ...modelAttributes,
    },
    errorsAttributes: {
      ...mapValues(modelAttributes, () => types.array(types.string)),
    },
  };

  return resolveFormStore(formValues)
    .named('ModelFormStore')
    .volatile(self => ({
      loading: false,
    }))
    .actions(self => ({
      ...actions(self),
      toRequest(service, method) {
        const request = get(self, `root.api.${service}.${method}`);
        if (!request) {
          throw new Error(`root.api.${service}.${method} not found`);
        }

        return request;
      },
      setEntities: (data) => {
        const entitiesCollectionStore = get(self, `root.entities.${entities}`);
        if (!entitiesCollectionStore) {
          throw new Error(`root.entities.${entities} not found`);
        }
        entitiesCollectionStore.merge(data);
      },
      addItemToCollection: (item) => {
        const collectionStore = get(self, `root.${collection}`);
        if (!collectionStore) {
          throw new Error(`root.${collection} not found`);
        }

        collectionStore.addItem(item)
      },
      submit: flow(function* (e) {
        e && e.preventDefault && e.preventDefault();

        self.submitting = true;

        self.clearErrors();

        let response;
        try {
          response = self.values.id ? yield self.toRequest(service, updateServiceMethod)(self.values.id, self.getValuesAsJson()) : yield self.toRequest(service, storeServiceMethod)(self.getValuesAsJson());

          self.setValues(response.data.data);
          self.setEntities(response.data.data);
          !self.values.id && self.addItemToCollection(response.data.data.id);

        } catch (error) {
          self.root.ui.toast.error(error);
          self.setErrors(get(error, 'response.data.errors', {}));
        }

        self.submitting = false;

        return response;
      }),
      load: flow(function* (id) {
        self.loading = true;
        self.reset();

        const request = get(self, `root.api.${service}.${loadServiceMethod}`);

        let response;
        if (request) {
          try {
            response = yield request(id);

            self.setValues(response.data.data);

          } catch (error) {
            self.root.ui.toast.error(error);
          }
        } else {
          throw new Error(`root.api.${service}.${loadServiceMethod} not found`);
        }

        self.loading = false;

        return response;
      }),
    }));
}
