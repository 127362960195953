import { types, getParent, flow, getRoot } from 'mobx-state-tree';
import { resolvePaginationStore, resolveFormStore } from 'mst-helper';
import { merge, get } from 'lodash';

export const resolveCollectionStore = (params = {}) => {
  const defaultParams = {
    modelType: types.model({}),
    filterValuesAttributes: {},
    defaultFilterValue: {},
    defaultPaginationValue: {},
    entities: null,
    service: null,
    serviceMethod: 'index',
  }

  const {
    modelType,
    filterValuesAttributes,
    defaultFilterValue,
    defaultPaginationValue,
    entities,
    service,
    serviceMethod,
  } = merge(defaultParams, params);

  const formValues = {
    valuesAttributes: filterValuesAttributes,
  }

  const Filter = resolveFormStore(formValues)
    .actions(self => ({
      submit(e) {
        e && e.preventDefault && e.preventDefault();

        self.submitting = true;
        getParent(self).pagination.setPage(1);
        self.submitting = false;
      },
    }));

  return types
    .model({
      loading: false,
      collection: types.array(modelType),
      filter: types.optional(Filter, defaultFilterValue),
      pagination: types.optional(
        resolvePaginationStore({
          onChangePage: (self) => getParent(self).load(),
          onChangePerPage: (self) => getParent(self).load(),
        }), defaultPaginationValue
      ),
    })
    .views(self => ({
      get root() {
        return getRoot(self);
      },
    }))
    .actions(self => ({
      addItem: (identifier) => {
        self.collection.unshift(identifier);
      },
      setCollection: (collection) => {
        const entitiesCollectionStore = get(self, `root.entities.${entities}`);
        if (!entitiesCollectionStore) {
          throw new Error(`root.entities.${entities} not found`);
        }
        entitiesCollectionStore.mergeMany(collection);
        self.collection.replace(collection.map(c => c.id));
      },
      load: flow(function* () {
        self.loading = true;

        self.collection.clear();

        const request = get(self, `root.api.${service}.${serviceMethod}`);

        if (request) {
          try {
            const response = yield request({
              filter: self.filter.getValuesAsJson(),
              page: self.pagination.page,
              per_page: self.pagination.perPage,
            });

            self.setCollection(response.data.data);
            self.pagination.setFromMeta(response.data.meta);
          } catch (error) {
            self.root.ui.toast.error(error);
          }
        } else {
          throw new Error(`root.api.${service}.${serviceMethod} not found`)
        }

        self.loading = false;
      }),
    }))
}
