import request from './request';

export const container = {
  index(params = {}) {
    return request.get('containers', { params });
  },
  update(id, values) {
    return request.put(`containers/${id}`, values)
  },
  statusesCount() {
    return request.get('containers/statusesCount');
  },
}
