import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export default () => {
  return <TableHead>
    <TableRow>
      <TableCell>ID/BOL#/CNTR#</TableCell>
      <TableCell>POL</TableCell>
      <TableCell>POD</TableCell>
      <TableCell>DEL</TableCell>
      <TableCell>FIRMS</TableCell>
      <TableCell>POD Discharged</TableCell>
      <TableCell>Released</TableCell>
      <TableCell>Terminal Pickuped</TableCell>
      <TableCell>LFD</TableCell>
      <TableCell>Empty Returned</TableCell>
      {/* <TableCell>TE Track</TableCell>
      <TableCell>T49 Track</TableCell> */}
      {/* <TableCell>GoFreight Track</TableCell> */}
      <TableCell>Freightower Track</TableCell>

    </TableRow>
  </TableHead>
}
