import React from 'react';
import { observer } from 'mobx-react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Link from '@material-ui/core/Link';
import { Link as RouteLink } from 'react-router-dom';




export default observer(({ terminal, location }) => {
  return <TableRow>
    <TableCell component="th" scope="row">{terminal.id}</TableCell>
    <TableCell>{terminal.firms_code}</TableCell>
    <TableCell>{terminal.name}</TableCell>
    <TableCell>{terminal.type}</TableCell>
    <TableCell>{terminal.tracking_link && <Link target="_blank" href={terminal.tracking_link}>{terminal.tracking_link}</Link>}</TableCell>
    <TableCell>{terminal.instruction_link && <Link target="_blank" href={terminal.instruction_link}>{terminal.instruction_link}</Link>}</TableCell>
    <TableCell>{terminal.memo}</TableCell>
    <TableCell>
      <IconButton color="primary" size="small" disabled={terminal.loading} component={RouteLink} to={{
        pathname: `/terminals/${terminal.id}`,
        state: { background: location }
      }}>
        <EditIcon fontSize="inherit" />
      </IconButton>
      <IconButton color="secondary" size="small" disabled={terminal.loading} onClick={terminal.delete}>
        <DeleteIcon fontSize="inherit" />
      </IconButton>
    </TableCell>
  </TableRow>
})
