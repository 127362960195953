import React from "react";
import { PropTypes, observer } from "mobx-react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {
  FormGroup,
  FormControlLabel,
  Switch,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Contacts from "../../../common/Contacts";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Form = observer(({ form }) => {
  const classes = useStyles();
  const { values, loading, submit, setValueByElement, submitting, errors } =
    form;

  const history = useHistory();

  const setOtherCodes = (otherCodes) => {
    setValueByElement({
      target: {
        name: "other_codes",
        value: otherCodes,
      },
    });
  };
  
  const updateOtherCode = (index, value) => {
    const updatedOtherCodes = [...(values.other_codes || [])];
    updatedOtherCodes[index] = value;
    setOtherCodes(updatedOtherCodes);
  };

  const addOtherCode = () => {
    const updatedOtherCodes = [...(values.other_codes || []), ""];
    setOtherCodes(updatedOtherCodes);
  };

  const removeOtherCode = (index) => {
    const updatedOtherCodes = [...values.other_codes];
    updatedOtherCodes.splice(index, 1);
    setOtherCodes(updatedOtherCodes);
  };

  const handleInputChange = (event, index) => {
    const { value } = event.target;
    updateOtherCode(index, value);
  };

  const handleAddOtherCode = () => {
    addOtherCode();
  };

  const handleRemoveOtherCode = (index) => {
    removeOtherCode(index);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    submit(e).then((done) => done && history.goBack());
  };

  return (
    <>
      <Box visibility={loading ? "visible" : "hidden"}>
        <LinearProgress />
      </Box>
      <form onSubmit={handleSubmit}>
        <TextField
          label="SCAC CODE"
          name="scac_code"
          margin="normal"
          value={values.scac_code || ""}
          onChange={setValueByElement}
          error={errors.scac_code.length > 0}
          helperText={errors.scac_code.join(" ")}
          fullWidth
          required
        />

        <FormLabel>
          Other CODES
          <Button type="button" onClick={handleAddOtherCode}>
            Add Other Code
          </Button>
        </FormLabel>

        {(values.other_codes || [])?.map((otherCode, index) => (
          <Box display="flex" key={index}>
            <TextField
              name={`other_codes[${index}]`}
              value={otherCode}
              onChange={(event) => handleInputChange(event, index)}
              error={errors?.other_codes?.[index]?.length > 0}
              helperText={errors?.other_codes?.[index]?.join(" ")}
              fullWidth
            />
            <Button type="button" onClick={() => handleRemoveOtherCode(index)}>
              Remove
            </Button>
          </Box>
        ))}

        <TextField
          label="NAME"
          name="name"
          margin="normal"
          value={values.name || ""}
          onChange={setValueByElement}
          error={errors.name.length > 0}
          helperText={errors.name.join(" ")}
          fullWidth
          required
        />
        <TextField
          label="TRACKING LINK"
          name="tracking_link"
          margin="normal"
          value={values.tracking_link || ""}
          onChange={setValueByElement}
          error={errors.tracking_link.length > 0}
          helperText={errors.tracking_link.join(" ")}
          fullWidth
        />
        <TextField
          label="INSTRUCTION LINK"
          name="instruction_link"
          margin="normal"
          value={values.instruction_link || ""}
          onChange={setValueByElement}
          error={errors.instruction_link.length > 0}
          helperText={errors.instruction_link.join(" ")}
          fullWidth
        />

        <FormControl component="div" margin="normal" multiline fullWidth>
          <FormLabel component="legend">AUTO TRACK</FormLabel>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={values.auto_track}
                  onChange={setValueByElement}
                />
              }
              name="auto_track"
            />
          </FormGroup>
        </FormControl>
        <FormControl component="div" margin="normal" multiline fullWidth>
          <FormLabel component="legend">TRACKING SOURCE</FormLabel>
          <RadioGroup
            row
            aria-label="position"
            name="tracking_source"
            onChange={setValueByElement}
          >
            <FormControlLabel
              value={1}
              checked={values.tracking_source === "1"}
              control={<Radio />}
              label="T49"
              labelPlacement="end"
            />
            <FormControlLabel
              value={0}
              checked={values.tracking_source === "0"}
              control={<Radio />}
              label="SPIDER"
              labelPlacement="end"
            />
          </RadioGroup>
        </FormControl>

        <TextField
          label="MEMO"
          name="memo"
          margin="normal"
          value={values.memo || ""}
          onChange={setValueByElement}
          error={errors.memo.length > 0}
          helperText={errors.memo.join(" ")}
          rows={4}
          multiline
          fullWidth
        />

        <Typography variant="h6">Contacts</Typography>
        <Contacts
          contacts={values.contacts}
          size="small"
          onAdd={form.addContact}
          onRemove={form.removeContact}
        />

        <Box
          mt={2}
          display="flex"
          flexDirection="row-reverse"
          className={classes.buttonGroup}
        >
          <Button
            variant="contained"
            disabled={submitting}
            onClick={history.goBack}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={submitting}
          >
            Save
          </Button>
        </Box>
      </form>
    </>
  );
});

export default Form;

Form.propTypes = {
  form: PropTypes.objectOrObservableObject.isRequired,
};
