import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Form from './Form';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';



export default inject('root')(
  observer(({ root }) => {

    useEffect(() => {
      root.smartTracking.form.load();
      root.oceanCarrier.collection.load();
    }, []);

    return <>
      <Container maxWidth="lg">
        <Typography variant="h5" gutterBottom>
          SmartTracking
      </Typography>

        <Form form={root.smartTracking.form} oceanCarriers={root.oceanCarrier.collection.collection} />
      </Container>
    </>
  })
);
