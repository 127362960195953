import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Form from './Form';

export default inject('root')(
  observer(({ root, oceanCarrierId }) => {
    oceanCarrierId = oceanCarrierId || useParams().oceanCarrierId;

    useEffect(() => {
      oceanCarrierId != 0 ? root.oceanCarrier.form.load(oceanCarrierId) : root.oceanCarrier.form.reset();
    }, []);

    return <>
      <Form form={root.oceanCarrier.form} />
    </>
  })
);
