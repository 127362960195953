import React from 'react';
import { observer } from 'mobx-react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Editable from '../../../common/Editable';
import { KeyboardDatePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import { format } from 'date-fns';
import Link from '@material-ui/core/Link';

import TocIcon from '@material-ui/icons/Toc';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import CircularProgress from '@material-ui/core/CircularProgress';



const useStyles = makeStyles({
  date: {
    minWidth: 250,
  },
});


export default observer(({ container }) => {
  const { bol } = container;

  const classes = useStyles();

  return <TableRow>
    <TableCell component="th" scope="row">
      <div style={{ paddingLeft:'0.6rem'}}>BOL ID: {bol.id}</div>
      <div>CNTR ID: {container.id}</div>
      <div style={{ display: 'flex' }}>{bol ? < >
        <Link color="primary" target="_blank" href={`https://www.track-trace.com/bol#${bol.number}`}>{bol.number}</Link>
        <span style={{ color: '#4757b9', marginLeft: '5px' }} onClick={bol.trackingSignedUrl}><TocIcon /></span>
      </> : ''}
      </div>
      <div>{container.number}</div>
    </TableCell>
    <TableCell>
      {bol ? <>
        <Box><EditableText field="pol_code" value={bol.pol_code} onUpdate={bol.update} /></Box>
        <Box><EditableText field="pol_name" value={bol.pol_name} onUpdate={bol.update} /></Box>
        {/* <Box display="flex" alignItems="center"><div>CODE:</div><div><EditableText field="pol_code" value={bol.pol_code} onUpdate={bol.update} /></div> </Box>
        <Box display="flex" alignItems="center"><div>NAME:</div><div><EditableText field="pol_name" value={bol.pol_name} onUpdate={bol.update} /></div> </Box> */}
        <Box display="flex" alignItems="center"><div>ETD:</div><div><EditableDate field="pol_etd" value={bol.pol_etd} onUpdate={bol.update} className={classes.date} /></div> </Box>
        <Box display="flex" alignItems="center"><div>ATD:</div><div><EditableDate field="pol_atd" value={bol.pol_atd} onUpdate={bol.update} className={classes.date} /></div></Box>
      </> : ''}
    </TableCell>
    <TableCell>
      {bol ? <>
        <Box><EditableText field="pod_code" value={bol.pod_code} onUpdate={bol.update} /></Box>
        <Box><EditableText field="pod_name" value={bol.pod_name} onUpdate={bol.update} /></Box>
        {/* <Box display="flex" alignItems="center"><div>CODE:</div><div><EditableText field="pod_code" value={bol.pod_code} onUpdate={bol.update} /></div> </Box>
        <Box display="flex" alignItems="center"><div>NAME:</div><div><EditableText field="pod_name" value={bol.pod_name} onUpdate={bol.update} /></div> </Box> */}
        <Box display="flex" alignItems="center"><div>ETA:</div><div><EditableDate field="pod_eta" value={bol.pod_eta} onUpdate={bol.update} className={classes.date} /></div> </Box>
        <Box display="flex" alignItems="center"><div>ATA:</div><div><EditableDate field="pod_ata" value={bol.pod_ata} onUpdate={bol.update} className={classes.date} /></div></Box>
      </> : ''}
    </TableCell>
    <TableCell>
      {bol ? <>
        <Box><EditableText field="del_code" value={bol.del_code} onUpdate={bol.update} /></Box>
        <Box><EditableText field="del_name" value={bol.del_name} onUpdate={bol.update} /></Box>
        {/* <Box display="flex" alignItems="center"><div>CODE:</div><div><EditableText field="del_code" value={bol.del_code} onUpdate={bol.update} /></div> </Box>
        <Box display="flex" alignItems="center"><div>NAME:</div><div><EditableText field="del_name" value={bol.del_name} onUpdate={bol.update} /></div> </Box> */}
        <Box display="flex" alignItems="center"><div>ETA:</div><div><EditableDate field="del_eta" value={bol.del_eta} onUpdate={bol.update} className={classes.date} /></div> </Box>
        <Box display="flex" alignItems="center"><div>ATA:</div><div><EditableDate field="del_atd" value={bol.del_atd} onUpdate={bol.update} className={classes.date} /></div></Box>
      </> : ''}
    </TableCell>
    <TableCell>{bol ? <EditableText field="firms_code" value={bol.firms_code} onUpdate={bol.update} /> : ''}</TableCell>

    <TableCell>
      <EditableDate field="manual_pod_discharged_at" value={container.pod_discharged_at} onUpdate={container.update} className={classes.date} />
    </TableCell>
    <TableCell>
      <Box display="flex" alignItems="center"><div>FRT.:</div><div><EditableDate field="manual_freight_released_at" value={container.freight_released_at} onUpdate={container.update} className={classes.date} /></div> </Box>
      <Box display="flex" alignItems="center"><div>CUST.:</div><div><EditableDate field="manual_customs_released_at" value={container.customs_released_at} onUpdate={container.update} className={classes.date} /></div> </Box>
      <Box display="flex" alignItems="center"><div>TERM.:</div><div><EditableDate field="manual_terminal_released_at" value={container.terminal_released_at} onUpdate={container.update} className={classes.date} /></div></Box>
    </TableCell>
    <TableCell><EditableDate field="manual_terminal_pickuped_at" value={container.terminal_pickuped_at} onUpdate={container.update} className={classes.date} /></TableCell>
    <TableCell><EditableDate field="manual_lfd" value={container.lfd} onUpdate={container.update} className={classes.date} /></TableCell>
    <TableCell><EditableDate field="manual_empty_returned_at" value={container.empty_returned_at} onUpdate={container.update} className={classes.date} /></TableCell>
    {/* <TableCell>
      {bol && <Tooltip title="Track"><IconButton color="primary" disabled={bol.tracking} onClick={bol.track}>{bol.tracking ? <CircularProgress size={24} /> : <TrackChangesIcon />}</IconButton></Tooltip>}
    </TableCell>
    <TableCell>
      {bol && <Tooltip title="Track"><IconButton color="primary" disabled={bol.tracking} onClick={bol.t49Track}>{bol.tracking ? <CircularProgress size={24} /> : <TrackChangesIcon />}</IconButton></Tooltip>}
    </TableCell> */}
    {/* <TableCell>
      {bol && <Tooltip title="Track"><IconButton color="primary" disabled={bol.tracking} onClick={bol.goFreightTrack}>{bol.tracking ? <CircularProgress size={24} /> : <TrackChangesIcon />}</IconButton></Tooltip>}
    </TableCell> */}
    <TableCell>
      {bol && <Tooltip title="Track"><IconButton color="primary" disabled={bol.tracking} onClick={bol.freightowerTrack}>{bol.tracking ? <CircularProgress size={24} /> : <TrackChangesIcon />}</IconButton></Tooltip>}
    </TableCell>
  </TableRow>
})

const EditableText = ({ field, value, onUpdate, className }) => {
  const handleChange = (v) => {
    onUpdate({ [field]: v });
  }

  return <Editable
    value={value}
    onHideEditChange={handleChange}
  >
    {({ value, onChange }) => <TextField className={className} value={value} onChange={e => onChange(e.target.value)} />}
  </Editable>
}

const EditableDate = ({ field, value, onUpdate, className }) => {
  const handleChange = (v) => {
    onUpdate({ [field]: v.toISOString() });
  }

  return <Editable
    value={value}
    render={value ? format(new Date(value), 'MM/dd/yyyy') : null}
    onHideEditChange={handleChange}
  >
    {({ value, onChange }) => <KeyboardDatePicker
      className={className}
      variant="inline"
      format="MM/dd/yyyy"
      value={value}
      onChange={onChange}
      InputAdornmentProps={{
        position: 'start',
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Button size="small" variant="outlined" onClick={e => onChange(new Date())}>Now</Button>
          </InputAdornment>
        )
      }}
    />}
  </Editable>
}
