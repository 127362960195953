import { types, flow, getParent, applySnapshot } from "mobx-state-tree";
import { resolveFormStore } from "mst-helper";

const TOKEN_KEY = "trackeasy-admin-token";

const createEmptyUserData = () => ({
  id: null,
  active: null,
  username: null,
  name: null,
  email: null,
});

const User = types.model({
  id: types.maybeNull(types.number),
  active: types.maybeNull(types.boolean),
  username: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
});

const formValues = {
  valuesAttributes: {
    username: types.maybeNull(types.string),
    password: types.maybeNull(types.string),
  },
};

const LoginFormStore = resolveFormStore(formValues)
  .named("LoginFormStore")
  .actions((self) => ({
    submit: flow(function* (e) {
      e && e.preventDefault && e.preventDefault();

      let response;
      try {
        self.submitting = true;
        yield self.root.auth.login(self.values.username, self.values.password);
      } catch (error) {
        self.root.ui.toast.error(error);
      }

      self.submitting = false;

      return response;
    }),
  }));

const AuthStore = types
  .model("AuthStore", {
    user: types.optional(User, createEmptyUserData),
    loginForm: types.optional(LoginFormStore, {}),
  })
  .views((self) => ({
    get root() {
      return getParent(self);
    },
    get isAuthenticated() {
      return self.user && !!self.user.id;
    },
  }))
  .actions((self) => {
    const updateUser = (data) => {
      applySnapshot(self.user, data);
    };

    const updateToken = (token) => {
      localStorage.setItem(TOKEN_KEY, token);
    };

    const getToken = () => {
      return localStorage.getItem(TOKEN_KEY);
    };

    const loggedin = (data, token) => {
      updateUser(data);
      updateToken(token);
    };

    const loggedout = () => {
      updateUser(createEmptyUserData());
    };

    const login = flow(function* (username, password) {
      try {
        const response = yield self.root.api.auth.login(username, password);

        const { token, ...data } = response.data.data;
        self.loggedin(data, token);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    });

    const loadUser = flow(function* () {
      try {
        const response = yield self.root.api.auth.user();
        updateUser(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    });

    const logout = flow(function* () {
      try {
        yield self.root.api.auth.logout();
        self.loggedout();
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    });

    return {
      loggedin,
      loggedout,
      login,
      loadUser,
      logout,
      getToken,
    };
  });

export default AuthStore;
