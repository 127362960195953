import React from 'react';
import { BrowserRouter, Route, Switch, Redirect, useLocation } from "react-router-dom";

import Navbar from '../components/layouts/Navbar';
import ScrollTop from '../components/layouts/ScrollTop';


import Login from '../components/pages/Login';
import Dashboard from '../components/pages/Dashboard';
import Containers from '../components/pages/container/Containers';
import Terminals from '../components/pages/terminal/Terminals';
import Terminal from '../components/pages/terminal/Terminal';
import TerminalModal from '../components/pages/terminal/Terminal/Modal';
import OceanCarriers from '../components/pages/oceanCarrier/OceanCarriers';
import OceanCarrier from '../components/pages/oceanCarrier/OceanCarrier';
import OceanCarrierModal from '../components/pages/oceanCarrier/OceanCarrier/Modal';
import SmartTracking from '../components/pages/smartTracking/SmartTracking';

export default ({ isAuthenticated }) => {
  return <BrowserRouter>
    {isAuthenticated && <Navbar />}
    <div style={{ padding: '10px' }}>
      <Routes isAuthenticated={isAuthenticated} />
    </div>
    <ScrollTop />
  </BrowserRouter>
}

const Routes = ({ isAuthenticated }) => {
  const location = useLocation();
  const background = location.state && location.state.background;

  return <>
    <Switch location={background || location}>
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/" component={Dashboard} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/containers" component={Containers} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/terminals/:terminalId" component={Terminal} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/terminals" component={Terminals} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/oceanCarriers/:oceanCarrierId" component={OceanCarrier} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/oceanCarriers" component={OceanCarriers} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/smartTracking" component={SmartTracking} />

      <Route exact path="/login" component={Login} />

      <Route render={() => 'Not Found'} />
    </Switch>

    {background && <Switch>
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/terminals/:terminalId" component={TerminalModal} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/oceanCarriers/:oceanCarrierId" component={OceanCarrierModal} />
    </Switch>}
  </>
}


const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component  {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );
}
