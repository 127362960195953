import React from 'react';
import { observer } from 'mobx-react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Link from '@material-ui/core/Link';
import { Link as RouteLink } from 'react-router-dom';




export default observer(({ oceanCarrier, location }) => {
  return <TableRow>
    <TableCell component="th" scope="row">{oceanCarrier.id}</TableCell>
    <TableCell>{oceanCarrier.scac_code}</TableCell>
    <TableCell>{oceanCarrier.other_codes?.join(', ')}</TableCell>
    <TableCell>{oceanCarrier.name}</TableCell>
    <TableCell>{oceanCarrier.tracking_link && <Link target="_blank" href={oceanCarrier.tracking_link}>{oceanCarrier.tracking_link}</Link>}</TableCell>
    <TableCell>{oceanCarrier.instruction_link && <Link target="_blank" href={oceanCarrier.instruction_link}>{oceanCarrier.instruction_link}</Link>}</TableCell>
    <TableCell>{oceanCarrier.memo}</TableCell>
    <TableCell>
      <IconButton color="primary" size="small" disabled={oceanCarrier.loading} component={RouteLink} to={{
        pathname: `/oceanCarriers/${oceanCarrier.id}`,
        state: { background: location }
      }}>
        <EditIcon fontSize="inherit" />
      </IconButton>
      <IconButton color="secondary" size="small" disabled={oceanCarrier.loading} onClick={oceanCarrier.delete}>
        <DeleteIcon fontSize="inherit" />
      </IconButton>
    </TableCell>
  </TableRow>
})
