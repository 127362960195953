import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import { observer } from 'mobx-react';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    width: 320,
    height: 32,
    padding: '2px 4px',
    display: 'flex',
    position: 'relative',
  },
  form: {

  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default observer(({ autoTrack }) => {
  const classes = useStyles();

  const { form } = autoTrack;

  return <Paper component="form" className={classes.root} onSubmit={form.submit}>
    <InputBase
      name="number"
      className={classes.input}
      value={form.values.number || ''}
      onChange={form.setValueByElement}
      placeholder="Track BOL#"
    />
    <IconButton type="submit" className={classes.iconButton} disabled={form.submitting}>
      <SearchIcon />
    </IconButton>
    {form.submitting && <Box size={28} right={5} position="absolute"><CircularProgress /></Box>}
  </Paper>
})
