import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import StatusSwitchBar from './StatusSwitchBar';
import AutoTrack from './AutoTrack';
import { PropTypes } from 'mobx-react';

const Header = ({ container }) => {
  return <>
    <Box display="flex" justifyContent={'flex-end'}>
      {/* <Box>
        <Typography variant="h5" gutterBottom>Containers</Typography>
      </Box>
      <Box ml={1} flexGrow={1}>
        <AutoTrack autoTrack={container.autoTrack} />
      </Box> */}
      <Box mb={1}>
        <StatusSwitchBar container={container} />
      </Box>
    </Box>

  </>
}

Header.propTypes = {
  container: PropTypes.observableObject.isRequired,
}

export default Header;
