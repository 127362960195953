import { types, getParent, flow, getRoot } from 'mobx-state-tree';
import { resolvePaginationStore, resolveFormStore } from 'mst-helper';
import { Container } from './models';
import { reaction } from 'mobx';


const filterValues = {
  valuesAttributes: {
    query: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    'bol.number': types.maybeNull(types.string),
    'number': types.maybeNull(types.string),
    status: 'all',
    smartTracking: false,
  }
};

const Filter = resolveFormStore(filterValues)
  .named('FilterFormStore')
  .actions(self => ({
    submit(e) {
      e && e.preventDefault && e.preventDefault();

      self.submitting = true;
      getParent(self).pagination.setPage(1);
      self.submitting = false;
    },
  }))

const StatusesCount = types
  .model({
    all: types.maybe(types.number),
    pre_alert: types.maybe(types.number),
    pending_released: types.maybe(types.number),
    pending_terminal_pickup: types.maybe(types.number),
    pending_empty_return: types.maybe(types.number),
    empty_returned: types.maybe(types.number),
  })

const AutoTrackForm = resolveFormStore({
  valuesAttributes: {
    number: types.maybeNull(types.string),
  },
})
  .named('AutoTrackForm')
  .actions(self => ({
    submit: flow(function* (e) {
      e && e.preventDefault && e.preventDefault();

      self.submitting = true;
      yield getParent(self).track(self.values.number);
      self.submitting = false;
    }),
  }))

const AutoTrack = types
  .model({
    form: types.optional(AutoTrackForm, {}),
  })
  .actions(self => ({
    track: flow(function* (number) {
      try {
        yield getRoot(self).api.bol.autoTrack(number);

        getParent(self).loadContainers();
      } catch (error) {
        getRoot(self).ui.toast.error(error);
      }
    }),
  }))

// const T49Track = types
//   .model({
//     form: types.optional(AutoTrackForm, {}),
//   })
//   .actions(self => ({
//     track: flow(function* (number) {
//       try {
//         yield getRoot(self).api.bol.t49Track(number);

//         getParent(self).loadContainers();
//       } catch (error) {
//         getRoot(self).ui.toast.error(error);
//       }
//     }),
//   }))

// const goFreightTrack = types
//   .model({
//     form: types.optional(AutoTrackForm, {}),
//   })
//   .actions(self => ({
//     track: flow(function* (number) {
//       try {
//         yield getRoot(self).api.bol.goFreightTrack(number);

//         getParent(self).loadContainers();
//       } catch (error) {
//         getRoot(self).ui.toast.error(error);
//       }
//     }),
//   }))

  const freightowerTrack = types
  .model({
    form: types.optional(AutoTrackForm, {}),
  })
  .actions(self => ({
    track: flow(function* (number) {
      try {
        yield getRoot(self).api.bol.freightowerTrack(number);

        getParent(self).loadContainers();
      } catch (error) {
        getRoot(self).ui.toast.error(error);
      }
    }),
  }))

const ContainerStore = types
  .model('ContainerStore', {
    containers: types.array(types.safeReference(Container)),
    loading: false,
    filter: types.optional(Filter, {}),
    pagination: types.optional(resolvePaginationStore({ onChangePage: (self) => getParent(self).loadContainers(), onChangePerPage: (self) => getParent(self).loadContainers() }), { perPage: 50 }),
    statusesCount: types.optional(StatusesCount, {}),
    statusesCountLoading: false,
    autoTrack: types.optional(AutoTrack, {}),
    //t49Track: types.optional(T49Track, {}),
    // goFreightTrack: types.optional(goFreightTrack, {}),
    freightowerTrack: types.optional(freightowerTrack, {}),
  })
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .volatile(self => ({
    filterValuesSmartTrackingDisposer: null,
  }))
  .actions(self => ({
    setContainers(containers) {
      self.root.entities.containers.mergeMany(containers);
      self.containers.replace(containers.map(c => c.id));
    },
    loadContainers: flow(function* () {
      self.loading = true;
      try {
        self.containers.clear();

        const response = yield self.root.api.container.index({
          filter: self.filter.getValuesAsJson(),
          include: 'bol',
          page: self.pagination.page,
          per_page: self.pagination.perPage,
        });

        self.setContainers(response.data.data);
        self.pagination.setFromMeta(response.data.meta);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    loadStatusesCount: flow(function* () {
      self.statusesCountLoading = true;
      try {
        const response = yield self.root.api.container.statusesCount();

        self.statusesCount = response.data.data;
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.statusesCountLoading = false;
    }),
    selectStatus(status) {
      self.filter.setValue('status', status);
      self.pagination.setPage(1);
    },



    beforeDestroy() {
      this.filterValuesSmartTrackingDisposer && this.filterValuesSmartTrackingDisposer();
    },
    afterCreate() {
      const smartTrackingKey = 'smart-tracking';
      const smartTracking = JSON.parse(localStorage.getItem(smartTrackingKey) || 'false');
      self.filter.setValue('smartTracking', smartTracking);

      this.filterValuesSmartTrackingDisposer = reaction(() => self.filter.values.smartTracking, (smartTracking) => {
        localStorage.setItem(smartTrackingKey, JSON.stringify(smartTracking));
        self.filter.submit();
      })
    },
  }));




export default ContainerStore;
