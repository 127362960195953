import request from './request';

export const terminal = {
  index(params = {}) {
    return request.get('terminals', { params });
  },
  show(id) {
    return request.get(`terminals/${id}`);
  },
  store(values) {
    return request.post(`terminals`, values)
  },
  update(id, values) {
    return request.put(`terminals/${id}`, values)
  },
  delete(id) {
    return request.delete(`terminals/${id}`)
  },
}
