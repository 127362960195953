import { types } from 'mobx-state-tree';
import { Terminal, terminalAttributes, ContactFormValues, resolveContactsActions } from './models';
import { resolveCollectionStore } from './CollectionStore';
import { resolveModelFormStore } from './ModelFormStore';

const Form = resolveModelFormStore({
  modelAttributes: { ...terminalAttributes, contacts: types.array(ContactFormValues) },
  collection: 'terminal.collection',
  entities: 'terminals',
  service: 'terminal',
  actions: self => resolveContactsActions(self),
});

const CollectionStore = resolveCollectionStore({
  modelType: types.safeReference(Terminal),
  filterValuesAttributes: {
    query: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
  },
  defaultPaginationValue: { perPage: 50 },
  entities: 'terminals',
  service: 'terminal',
});

const TerminalStore = types
  .model('TerminalStore', {
    collection: types.optional(CollectionStore, {}),
    form: types.optional(Form, {}),
  })

export default TerminalStore;
