import { types, flow } from 'mobx-state-tree';

import { resolveModel, Container } from './index';

export const bolAttributes = {
  number: types.string,
  scac_code: types.maybeNull(types.string),
  pol_code: types.maybeNull(types.string),
  pol_name: types.maybeNull(types.string),
  pod_code: types.maybeNull(types.string),
  pod_name: types.maybeNull(types.string),
  del_code: types.maybeNull(types.string),
  del_name: types.maybeNull(types.string),
  firms_code: types.maybeNull(types.string),
  pol_etd: types.maybeNull(types.string),
  pol_atd: types.maybeNull(types.string),
  pod_eta: types.maybeNull(types.string),
  pod_ata: types.maybeNull(types.string),
  del_eta: types.maybeNull(types.string),
  del_ata: types.maybeNull(types.string),
  containers: types.array(types.late(() => types.reference(Container))),
}

export const BOL = resolveModel({
  modelAttributes: bolAttributes,
  entities: 'bols',
  service: 'bol',
})
  .named('BOLModal')
  .volatile(self => ({
    tracking: false,
  }))
  .actions(self => ({
    // track: flow(function* () {
    //   if (!self.id) {
    //     return;
    //   }

    //   try {
    //     self.tracking = true;

    //     const response = yield self.root.api.bol.track(self.id);

    //     self.toEntities().merge(response.data.data);
    //   } catch (error) {
    //     self.root.ui.toast.error(error);
    //   }

    //   self.tracking = false;
    // }),
    // t49Track: flow(function* () {
    //   if (!self.id) {
    //     return;
    //   }

    //   try {
    //     self.tracking = true;

    //     const response = yield self.root.api.bol.t49Track(self.id);

    //     self.toEntities().merge(response.data.data);
    //   } catch (error) {
    //     self.root.ui.toast.error(error);
    //   }

    //   self.tracking = false;
    // }),
    // goFreightTrack: flow(function* () {

    //   if (!self.id) {
    //     return;
    //   }

    //   try {
    //     self.tracking = true;

    //     const response = yield self.root.api.bol.goFreightTrack(self.id);

    //     self.toEntities().merge(response.data.data);
    //   } catch (error) {
    //     self.root.ui.toast.error(error);
    //   }

    //   self.tracking = false;
    // }),

    freightowerTrack: flow(function* () {

      if (!self.id) {
        return;
      }

      try {
        self.tracking = true;

        const response = yield self.root.api.bol.freightowerTrack(self.id);

        self.toEntities().merge(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }

      self.tracking = false;
    }),

    trackingSignedUrl: flow(function* () {

      if (!self.number) {
        return;
      }

      try {
        self.tracking = true;

        const response = yield self.root.api.bol.trackingSignedUrl(self.number);
        if (response.data.url) {
          window.open(decodeURIComponent(response.data.url), '_target');
        }
      } catch (error) {
        self.root.ui.toast.error(error);
      }

      self.tracking = false;
    }),

  }));


