import { types, getRoot, flow } from 'mobx-state-tree';
import { get, merge } from 'lodash';


export const resolveModel = (params = {}) => {
  const defaultParams = {
    modelAttributes: {},
    entities: null,
    service: null,
    updateServiceMethod: 'update',
    deleteServiceMethod: 'delete',
  }

  const {
    modelAttributes,
    entities,
    service,
    updateServiceMethod,
    deleteServiceMethod,
  } = merge(defaultParams, params);

  return types
    .model({
      id: types.identifierNumber,
      created_at: types.maybeNull(types.string),
      updated_at: types.maybeNull(types.string),
      ...modelAttributes,
    })
    .views(self => ({
      get root() {
        return getRoot(self);
      },
    }))
    .volatile(self => ({
      loading: false,
    }))
    .actions(self => ({
      smartSet(snapshot) {
        Object.keys(snapshot).forEach(key => {
          if (self.hasOwnProperty(key)) {
            self[key] = snapshot[key];
          }
        })
      },
      toRequest(service, method) {
        const request = get(self, `root.api.${service}.${method}`);
        if (!request) {
          throw new Error(`root.api.${service}.${method} not found`);
        }

        return request;
      },
      toEntities() {
        const entitiesCollectionStore = get(self, `root.entities.${entities}`);
        if (!entitiesCollectionStore) {
          throw new Error(`root.entities.${entities} not found`);
        }
        return entitiesCollectionStore;
      },
      update: flow(function* (values) {
        self.loading = true;

        let response;
        try {
          response = yield self.toRequest(service, updateServiceMethod)(self.id, values);

          self.toEntities().merge(response.data.data);

        } catch (error) {
          self.root.ui.toast.error(error);
        }

        self.loading = false;

        return response;
      }),
      delete: flow(function* () {
        if (window.confirm('Are you sure to delete it')) {
          self.loading = true;

          let response;
          try {
            yield self.toRequest(service, deleteServiceMethod)(self.id);

            self.toEntities().remove(self);

            return true;
          } catch (error) {
            self.root.ui.toast.error(error);
          }

          self.loading = false;

          return response;
        }
      }),
    }))
}
