import request from './request';

export const auth = {
  user() {
    return request.get('user');
  },
  login(username, password) {
    return request.post('login', { username, password })
  },
  csrf() {
    return request.get(process.env.REACT_APP_SERVER_BASE_URL + '/sanctum/csrf-cookie');
  },
  logout() {
    return request.delete('logout');
  }
}
