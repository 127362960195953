import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export default () => {
  return <TableHead>
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell>FIRMS CODE</TableCell>
      <TableCell>NAME</TableCell>
      <TableCell>TYPE</TableCell>
      <TableCell>TRACKING LINK</TableCell>
      <TableCell>INSTRUCTION LINK</TableCell>
      <TableCell>MEMO</TableCell>
      <TableCell>Actions</TableCell>
    </TableRow>
  </TableHead>
}
