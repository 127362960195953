import { types } from 'mobx-state-tree';
import { OceanCarrier, oceanCarrierAttributes, ContactFormValues, resolveContactsActions } from './models';
import { resolveCollectionStore } from './CollectionStore';
import { resolveModelFormStore } from './ModelFormStore';

const Form = resolveModelFormStore({
  modelAttributes: { ...oceanCarrierAttributes, contacts: types.array(ContactFormValues) },
  collection: 'oceanCarrier.collection',
  entities: 'oceanCarriers',
  service: 'oceanCarrier',
  actions: self => resolveContactsActions(self),
})

const CollectionStore = resolveCollectionStore({
  modelType: types.safeReference(OceanCarrier),
  filterValuesAttributes: {
    query: types.maybeNull(types.string),
  },
  defaultPaginationValue: { perPage: 50 },
  entities: 'oceanCarriers',
  service: 'oceanCarrier',
});

const OceanCarrierStore = types
  .model('OceanCarrierStore', {
    collection: types.optional(CollectionStore, {}),
    form: types.optional(Form, {}),
  })

export default OceanCarrierStore;
