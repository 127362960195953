import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Form from './Form';

export default inject('root')(
  observer(({ root, terminalId }) => {
    terminalId = terminalId || useParams().terminalId;

    useEffect(() => {
      terminalId != 0 ? root.terminal.form.load(terminalId) : root.terminal.form.reset();
    }, []);

    return <>
      <Form form={root.terminal.form} />
    </>
  })
);
