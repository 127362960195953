import { types } from 'mobx-state-tree';
import { resolveModel, BOL, ContainerEvent } from './index';

const containerAttributes = {
  bol_id: types.number,
  number: types.string,
  scac_code: types.maybeNull(types.string),
  shipment_status: types.maybeNull(types.number),
  shipment_status_name: types.maybeNull(types.string),
  seal_number: types.maybeNull(types.string),
  size: types.maybeNull(types.string),
  weight_lb: types.maybeNull(types.string),
  manual_lfd: types.maybeNull(types.string),
  auto_lfd: types.maybeNull(types.string),
  lfd: types.maybeNull(types.string),
  manual_pod_discharged_at: types.maybeNull(types.string),
  auto_pod_discharged_at: types.maybeNull(types.string),
  pod_discharged_at: types.maybeNull(types.string),
  manual_freight_released_at: types.maybeNull(types.string),
  auto_freight_released_at: types.maybeNull(types.string),
  freight_released_at: types.maybeNull(types.string),
  manual_customs_released_at: types.maybeNull(types.string),
  auto_customs_released_at: types.maybeNull(types.string),
  customs_released_at: types.maybeNull(types.string),
  manual_terminal_released_at: types.maybeNull(types.string),
  auto_terminal_released_at: types.maybeNull(types.string),
  terminal_released_at: types.maybeNull(types.string),
  manual_terminal_pickuped_at: types.maybeNull(types.string),
  auto_terminal_pickuped_at: types.maybeNull(types.string),
  terminal_pickuped_at: types.maybeNull(types.string),
  manual_empty_returned_at: types.maybeNull(types.string),
  auto_empty_returned_at: types.maybeNull(types.string),
  empty_returned_at: types.maybeNull(types.string),
  bol: types.maybe(types.late(() => types.reference(BOL))),
  events: types.array(types.late(() => types.reference(ContainerEvent))),
}

export const Container = resolveModel({
  modelAttributes: containerAttributes,
  entities: 'containers',
  service: 'container',
});
