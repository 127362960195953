import React from 'react';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import RefreshIcon from '@material-ui/icons/Refresh';
import { PropTypes } from 'mobx-react';
import Chip from '@material-ui/core/Chip';


const StatusSwitchBar = observer(({ container }) => {
  const { statusesCountLoading, statusesCount, selectStatus, loadStatusesCount, filter } = container;

  return <>
    <ButtonGroup size="small" color="primary" variant="outlined">
      {Object.keys(statusesCount).map(key => (
        <Button key={key} disabled={statusesCountLoading} variant={filter.values.status === key ? 'contained' : 'outlined'} onClick={() => selectStatus(key)}>
          {key.replaceAll('_', ' ')}
          &nbsp;
          <Chip label={statusesCount[key]} size="small" />
        </Button>
      ))}
      <Button disabled={statusesCountLoading} onClick={loadStatusesCount}><RefreshIcon /></Button>
    </ButtonGroup>
  </>
});


StatusSwitchBar.propTypes = {
  container: PropTypes.observableObject.isRequired,
}


export default StatusSwitchBar;
