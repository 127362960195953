import { types, getParent } from 'mobx-state-tree';

const AppStore = types
  .model('AppStore', {
    appLoaded: false,
  })
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    setApploaded(loaded) {
      self.appLoaded = loaded;
    },
    afterAttach() {
      self.root.start();
    },
  }));

export default AppStore;
