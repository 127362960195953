import React from 'react';
import { observer } from 'mobx-react';
import { TERMINAL_TYPES } from '../../../../data';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Paper,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {

  },
  form: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    '& > :nth-last-child(2)': {
      marginRight: 'auto',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    }

  },
  query: {
    minWidth: '350px',
  },
  select: {
    minWidth: '300px',
  }
}));

export default observer(({ filter }) => {
  const classes = useStyles();
  return <>
    <Box mb={2}>
      <Paper>
        <form className={classes.form} noValidate autoComplete="off" onSubmit={filter.submit}>
          <TextField className={classes.query} size="small" label="FIRMS CODE/NAME/MEMO" variant="outlined" value={filter.values.query || ''} name="query" onChange={filter.setValueByElement} />
          <FormControl size="small" variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">Type</InputLabel>
            <Select
              className={classes.select}
              labelId="type"
              id="type"
              name="type"
              value={filter.values.type || ''}
              onChange={filter.setValueByElement}
              label="Type"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {
                TERMINAL_TYPES.map((type, index) => <MenuItem key={index} value={type}>{type}</MenuItem>)
              }
            </Select>
          </FormControl>

          <Button type="submit" color="primary" variant="outlined" disabled={filter.submitting}>Search</Button>
        </form>
      </Paper>
    </Box>
  </>
})
