import React, { useState } from "react";
import OutsideClickHandler from 'react-outside-click-handler';
import { isFunction } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';


const Editable = ({
  color = 'primary',
  size = "small",
  render,
  value,
  children,
  onShowEdit,
  onHideEdit,
  onHideEditChange,
  ...props
}) => {
  const [isEditing, setEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  const hide = () => {
    setEditing(false);
    onHideEdit && onHideEdit(currentValue);

    if (currentValue !== value) {
      onHideEditChange && onHideEditChange(currentValue);
    }
  }

  const show = () => {
    setEditing(true);
    setCurrentValue(value);
    onShowEdit && onShowEdit();
  }

  const onChange = v => setCurrentValue(v);

  return (
    <div {...props} style={{ width: '100%', height: '100%' }}>
      {isEditing ? (
        <OutsideClickHandler onOutsideClick={hide}>
          {children({
            value: currentValue,
            onChange,
          })}
        </OutsideClickHandler>
      ) : (
          <div
            onClick={show}
            style={{ width: '100%', height: '100%' }}
          >
            {render && isFunction(render)
              ? render()
              : <Text value={render !== undefined ? render : value} color={color} size={size} />
            }
          </div>
        )}
    </div>
  );

};

const Text = ({ value, size = "sm", color = "primary" }) => value ? <Button color={color} size={size}>{value}</Button> : <IconButton size={size} color={color}><EditIcon fontSize="inherit" /></IconButton>

export default Editable;
