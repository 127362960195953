import { types } from 'mobx-state-tree';
import { resolveModel, BOL } from './index';

export const shipmentAttributes = {
  bol_id: types.number,
  bol_number: types.string,
  reference: types.maybeNull(types.string),
  bol: types.maybe(types.late(() => types.reference(BOL))),
}

export const Shipment = resolveModel({
  modelAttributes: shipmentAttributes,
  entities: 'shipments',
  service: 'shipment',
});
