import { types } from 'mobx-state-tree';

import { resolveModel, Contact } from './index';


export const terminalAttributes = {
  name: types.maybeNull(types.string),
  firms_code: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  tracking_link: types.maybeNull(types.string),
  instruction_link: types.maybeNull(types.string),
  memo: types.maybeNull(types.string),
  contacts: types.array(types.late(() => types.reference(Contact))),
}

export const Terminal = resolveModel({
  modelAttributes: terminalAttributes,
  entities: 'terminals',
  service: 'terminal',
});
