import { get } from 'lodash';
import { types } from 'mobx-state-tree';

import { resolveModel } from './index';

export const contactAttributes = {
  name: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  emailable: false,
  contactable_type: types.maybeNull(types.string),
  contactable_id: types.maybeNull(types.number),
  phone: types.maybeNull(types.string),
  cell: types.maybeNull(types.string),
  fax: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  remark: types.maybeNull(types.string),
}

export const ContactFormValues = types
  .model({
    id: 0,
    ...contactAttributes,
  })
  .actions(self => ({
    setValue(name, value) {
      self[name] = value;
    },
    setValueByElement(e) {
      const { name, value, checked, type } = e.target;
      self.setValue(name, type === 'checkbox' ? checked : value);
    }
  }))

export const resolveContactsActions = (self, contactsField = 'values.contacts') => ({
  toContacts() {
    return get(self, contactsField);
  },
  addContact() {
    self.toContacts().push({})
  },
  removeContact(contact) {
    self.toContacts().remove(contact);
  }
})

export const Contact = resolveModel({
  modelAttributes: contactAttributes,
  entities: 'contacts',
  service: 'contact',
});
