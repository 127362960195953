import request from './request';

export const smartTracking = {
  show() {
    return request.get('smartTracking');
  },
  update(values) {
    return request.put(`smartTracking`, values)
  },
}
