import { isArray, isPlainObject } from 'lodash';
import { types, getRoot, getIdentifier } from 'mobx-state-tree';
import {
  Container,
  BOL,
  Shipment,
  ContainerEvent,
  Terminal,
  OceanCarrier,
  Contact,
} from './models';

// TODO garbage collection for unused entities

const DEFAULT_IDENTIFIER_KEY = 'id';

const smartApplySnapshot = (model, snapshot) => {
  Object.keys(snapshot).forEach(key => {
    if (model.hasOwnProperty(key)) {
      model[key] = snapshot[key];
    }
  })
}

const resolveRelationship = (relation, entitiesKey, identifierKey = DEFAULT_IDENTIFIER_KEY) => {
  return {
    relation,
    entitiesKey,
    identifierKey,
  }
}

const resolveCollectionStore = (model, relationships = []) => {
  return types
    .model({
      collection: types.map(model),
    })
    .views(self => ({
      get root() {
        return getRoot(self);
      },
      get entitiesStore() {
        return self.root.entities;
      },
    }))
    .actions(self => ({
      remove(entity) {
        self.collection.delete(getIdentifier(entity));
      },
      merge(snapshot, identifierKey = DEFAULT_IDENTIFIER_KEY) {
        if (!isPlainObject(snapshot)) {
          throw new Error('1st params in merge method must be a plain object.');
        }

        const identifier = snapshot[identifierKey]

        relationships.forEach(relationship => self.mergeRelation(snapshot, relationship));

        if (self.collection.has(identifier)) {
          smartApplySnapshot(self.collection.get(identifier), self.modifySnapshotRelations(snapshot, relationships));
        } else {
          self.collection.set(identifier, self.modifySnapshotRelations(snapshot, relationships));
        }
      },
      mergeMany(snapshotArray, identifierKey = DEFAULT_IDENTIFIER_KEY) {
        Array.from(snapshotArray).forEach(snapshot => self.merge(snapshot, identifierKey));
      },
      mergeRelation(snapshot, relationship) {
        const { relation, entitiesKey, identifierKey } = relationship;
        const relationSnapshot = snapshot[relation];
        if (relationSnapshot) {
          isArray(relationSnapshot) ? self.entitiesStore[entitiesKey].mergeMany(relationSnapshot, identifierKey) : self.entitiesStore[entitiesKey].merge(relationSnapshot, identifierKey);
        }
      },
      modifySnapshotRelations(snapshot, relationships) {
        relationships.forEach(relationship => {
          const { relation, identifierKey } = relationship;
          const relationSnapshot = snapshot[relation];
          if (relationSnapshot) {
            snapshot[relation] = isArray(relationSnapshot) ? relationSnapshot.map(r => r[identifierKey]) : snapshot[relation][identifierKey];
          }
        })

        return snapshot;
      },
    }));
}


export default types
  .model({
    containers: types.optional(
      resolveCollectionStore(Container, [
        resolveRelationship('bol', 'bols'),
        resolveRelationship('events', 'containerEvents'),
      ]),
      {},
    ),

    bols: types.optional(
      resolveCollectionStore(BOL, [
        resolveRelationship('containers', 'containers'),
      ]),
      {},
    ),

    shipments: types.optional(
      resolveCollectionStore(Shipment, [
        resolveRelationship('bol', 'bols'),
      ]),
      {},
    ),
    containerEvents: types.optional(
      resolveCollectionStore(ContainerEvent, [
        resolveRelationship('container', 'containers'),
      ]),
      {},
    ),
    terminals: types.optional(
      resolveCollectionStore(Terminal, [
        resolveRelationship('contacts', 'contacts'),
      ]),
      {},
    ),
    oceanCarriers: types.optional(
      resolveCollectionStore(OceanCarrier, [
        resolveRelationship('contacts', 'contacts'),
      ]),
      {},
    ),
    contacts: types.optional(
      resolveCollectionStore(Contact),
      {},
    ),
  });
