import request from './request';

export const oceanCarrier = {
  index(params = {}) {
    return request.get('oceanCarriers', { params });
  },
  show(id) {
    return request.get(`oceanCarriers/${id}`);
  },
  store(values) {
    return request.post(`oceanCarriers`, values)
  },
  update(id, values) {
    return request.put(`oceanCarriers/${id}`, values)
  },
  delete(id) {
    return request.delete(`oceanCarriers/${id}`)
  },
}
