import { types, flow } from 'mobx-state-tree';
import { smartTrackingAttributes } from './models';
import { resolveFormStore } from 'mst-helper';
import { mapValues, get } from 'lodash';
import { reaction } from 'mobx';

const Form = resolveFormStore({
  valuesAttributes: {
    ...smartTrackingAttributes,
  },
  errorsAttributes: {
    ...mapValues(smartTrackingAttributes, () => types.array(types.string)),
  },
})
  .volatile(self => ({
    loading: false,
    valuesReationDisposer: null,
  }))
  .actions(self => ({
    load: flow(function* () {
      self.loading = true;
      self.reset();

      let response;
      try {
        response = yield self.root.api.smartTracking.show();

        self.setValues(response.data.data);

      } catch (error) {
        self.root.ui.toast.error(error);
      }

      self.loading = false;

      return response;
    }),
    submit: flow(function* (e) {
      e && e.preventDefault && e.preventDefault();

      self.submitting = true;

      self.clearErrors();

      let response;
      try {
        response = yield self.root.api.smartTracking.update(self.getValuesAsJson());

        self.setValues(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
        self.setErrors(get(error, 'response.data.errors', {}));
      }

      self.submitting = false;

      return response;
    }),
    beforeDestroy() {
      self.valuesReationDisposer && self.valuesReationDisposer();
    },
    afterCreate() {
      reaction(() => self.values.all_scac_codes, (value) => {
        if (value) {
          self.setValue('scac_codes', []);
        }
      });
    },
  }));

const SmartTrackingStore = types
  .model('SmartTrackingStore', {
    form: types.optional(Form, {}),
  });

export default SmartTrackingStore;
