import { types } from 'mobx-state-tree';
import { resolveModel, Container } from './index';

const containerEventAttributes = {
  container_id: types.number,
  code: types.maybeNull(types.string),
  activity: types.maybeNull(types.string),
  location_name: types.maybeNull(types.string),
  vessel_name: types.maybeNull(types.string),
  voyage_number: types.maybeNull(types.string),
  actual_at: types.maybeNull(types.string),
  estimated_at: types.maybeNull(types.string),
  container: types.maybe(types.late(() => types.reference(Container))),
}

export const ContainerEvent = resolveModel({
  modelAttributes: containerEventAttributes,
  entities: 'containerEvents',
  service: 'containerEvent',
});
