import { types, getEnv } from 'mobx-state-tree';
import AuthStore from './AuthStore';
import UIStore from './UIStore';
import AppStore from './AppStore';
import EntitiesStore from './EntitiesStore';

import SmartTrackingStore from './SmartTrackingStore';

import ContainerStore from './ContainerStore';
import TerminalStore from './TerminalStore';
import OceanCarrierStore from './OceanCarrierStore';



const RootStore = types
  .model('RootStore', {
    auth: types.optional(AuthStore, {}),
    ui: types.optional(UIStore, {}),
    app: types.optional(AppStore, {}),
    entities: types.optional(EntitiesStore, {}),

    smartTracking: types.optional(SmartTrackingStore, {}),

    container: types.optional(ContainerStore, {}),
    terminal: types.optional(TerminalStore, {}),
    oceanCarrier: types.optional(OceanCarrierStore, {}),
  })
  .views(self => ({
    get api() {
      return getEnv(self).api;
    },
  }))
  .actions(self => ({
    start() {
      self.auth.loadUser().then(() => self.app.setApploaded(true));
    },
  }))

export default RootStore;
