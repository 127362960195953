import React from 'react';
import { observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {

  },
  form: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    '& > :nth-last-child(2)': {
      marginRight: 'auto',
    },
  },
  query: {
    minWidth: '350px',
  },
}));

export default observer(({ filter }) => {
  const classes = useStyles();

  return <>
    <Box mb={2}>
      <Paper>
        <form className={classes.form} noValidate autoComplete="off" onSubmit={filter.submit}>
          <TextField className={classes.query} size="small" label="SCAC CODE/NAME/MEMO/CONTACT" variant="outlined" name="query" value={filter.values.query || ''} onChange={filter.setValueByElement} />
          <Button type="submit" color="primary" variant="outlined" disabled={filter.submitting}>Search</Button>
        </form>
      </Paper>
    </Box>
  </>
})
