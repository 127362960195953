import request from './request';

export const bol = {
  index(params = {}) {
    return request.get('bols', { params });
  },
  update(id, values) {
    return request.put(`bols/${id}`, values)
  },
  track(id) {
    return request.put(`bols/${id}/track`);
  },
  autoTrack(number) {
    return request.post(`bols/${number}/autoTrack`);
  },
  // t49Track(id) {
  //   return request.put(`bols/${id}/t49Track`);
  // },
  // goFreightTrack(id) {
  //   return request.put(`bols/${id}/goFreightTrack`);
  // },
  freightowerTrack(id) {
    return request.put(`bols/${id}/freightowerTrack`);
  },
  trackingSignedUrl(mblNumber) {
    return request.get(`bols/signedUrl/${mblNumber}`)
  }
}
