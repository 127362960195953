import React from 'react';
import { PropTypes, observer } from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Contacts from '../../../common/Contacts';

import { TERMINAL_TYPES } from '../../../../data';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const Form = observer(({ form }) => {
  const classes = useStyles();
  const { values, loading, submit, setValueByElement, submitting, errors } = form;

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    submit(e).then((done) => done && history.goBack());
  }

  return <>
    <Box visibility={loading ? 'visible' : 'hidden'}><LinearProgress /></Box>
    <form onSubmit={handleSubmit}>
      <TextField
        label="FIRMS CODE"
        name="firms_code"
        margin="normal"
        value={values.firms_code || ''}
        onChange={setValueByElement}
        error={errors.firms_code.length > 0}
        helperText={errors.firms_code.join(' ')}
        fullWidth
        required
      />
      <TextField
        label="NAME"
        name="name"
        margin="normal"
        value={values.name || ''}
        onChange={setValueByElement}
        error={errors.name.length > 0}
        helperText={errors.name.join(' ')}
        fullWidth
        required
      />
      <TextField
        select
        label="TYPE"
        name="type"
        margin="normal"
        value={values.type || ''}
        onChange={setValueByElement}
        error={errors.type.length > 0}
        helperText={errors.type.join(' ')}
        fullWidth
        required
      >
        <MenuItem value="">Select</MenuItem>
        {TERMINAL_TYPES.map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)}
      </TextField>
      <TextField
        label="TRACKING LINK"
        name="tracking_link"
        margin="normal"
        value={values.tracking_link || ''}
        onChange={setValueByElement}
        error={errors.tracking_link.length > 0}
        helperText={errors.tracking_link.join(' ')}
        fullWidth
      />
      <TextField
        label="INSTRUCTION LINK"
        name="instruction_link"
        margin="normal"
        value={values.instruction_link || ''}
        onChange={setValueByElement}
        error={errors.instruction_link.length > 0}
        helperText={errors.instruction_link.join(' ')}
        fullWidth
      />
      <TextField
        label="MEMO"
        name="memo"
        margin="normal"
        value={values.memo || ''}
        onChange={setValueByElement}
        error={errors.memo.length > 0}
        helperText={errors.memo.join(' ')}
        rows={4}
        multiline
        fullWidth
      />

      <Typography variant="h6">
        Contacts
      </Typography>
      <Contacts contacts={values.contacts} size="small" onAdd={form.addContact} onRemove={form.removeContact} />

      <Box mt={2} display="flex" flexDirection="row-reverse" className={classes.buttonGroup}>
        <Button
          variant="contained"
          disabled={submitting}
          onClick={history.goBack}
        >
          Cancel
      </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={submitting}
        >
          Save
      </Button>
      </Box>
    </form>
  </>
})

export default Form;

Form.propTypes = {
  form: PropTypes.objectOrObservableObject.isRequired,
}
