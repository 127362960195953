
export const TERMINAL_TYPES = [
  'RAILROAD TERMINALS',
  'EQUIPMENT DEPOTS',
  'OCEAN PORTS',
  'INSPECTION FACILITY',
  'BONDED WAREHOUSE',
  'CUSTOMS ADMIN SITE',
  'CUSTOMS CONTAINER STATION',
  'EXAM SITE',
];
