import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Header from './Header';
import Table from './Table';
import Filter from './Filter';
import Pagination from './Pagination';

export default inject('root')(
  observer(({ root }) => {
    useEffect(() => {
      root.oceanCarrier.collection.load();
    }, []);

    const { loading, collection, pagination, filter } = root.oceanCarrier.collection;

    return <>
      <Header />

      <Filter filter={filter} />

      <Table
        loading={loading}
        oceanCarriers={collection}
      />

      <Pagination pagination={pagination} />
    </>
  })
);
