import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Header from './Header';
import Table from './Table';
import Filter from './Filter';
import Pagination from './Pagination';


export default inject('root')(
  observer(({ root }) => {
    useEffect(() => {
      root.container.loadContainers();
      root.container.loadStatusesCount();
    }, []);

    const { loading, containers, pagination, filter } = root.container;

    return <>
      <Header container={root.container} />

      <Filter filter={filter} />

      <Table
        loading={loading}
        containers={containers}
      />

      <Pagination pagination={pagination} />
    </>
  })
);
