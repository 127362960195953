import { types } from "mobx-state-tree";

import { resolveModel, Contact } from "./index";

export const oceanCarrierAttributes = {
  name: types.maybeNull(types.string),
  scac_code: types.maybeNull(types.string),
  other_codes:  types.maybeNull(types.array(types.late(() => types.maybeNull(types.string)))),
  tracking_link: types.maybeNull(types.string),
  instruction_link: types.maybeNull(types.string),
  memo: types.maybeNull(types.string),
  auto_track: types.maybeNull(types.boolean),
  tracking_source: types.maybeNull(types.string),
  contacts: types.array(types.late(() => types.reference(Contact))),
};

export const OceanCarrier = resolveModel({
  modelAttributes: oceanCarrierAttributes,
  entities: "oceanCarriers",
  service: "oceanCarrier",
});
